


































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import formatter from "@/mixins/formatter";
import { MsiIndex } from "@/smartmsi";
import getUnicodeFlagIcon from "country-flag-icons/unicode";

import StockExchangeHistory from "@/components/stock/StockExchangeHistory.vue";
import Flashable from "@/components/stock/Flashable.vue";
import StockSamples from "@/components/stock/StockSamples.vue";
import MsiIndexChart from "@/components/stock/MsiIndexChart.vue";
import { AxiosResponse } from "axios";
import StockExchangeDetails from "@/components/stock/StockExchangeDetails.vue";


@Component({
  components: {StockExchangeDetails, MsiIndexChart, StockSamples, Flashable, StockExchangeHistory},
  mixins: [formatter]
})
export default class StockView extends Vue {

  @Prop() indexId!: number;

  startYear = '';
  endYear = '';

  currentTab = 0;

  toggleBase = 0;

  index: Partial<MsiIndex> = {};
  loading = true;

  withMsi = 0;
  msiBase = 0;

  live = false;

  last = [this.toggleBase == 0 ? 0 : 20000, 0];


  samples = [{
    '3M': 0,
    '6M': 0,
    'JAN': 0,
    'YTD': 0,
    'ALL': this.toggleBase == 0 ? this.index.stockExchange?.last.now : 20000
  }, {
    '3M': 0,
    '6M': 0,
    'JAN': 0,
    'YTD': 0,
    'ALL': this.index.stockExchange?.last.now
  }
  ];

  @Watch("toggleBase")
  onSourceChanged(newValue) {
    this.msiBase = newValue ? 20000 : 0
  }

  flag(code: string) {
    return getUnicodeFlagIcon(code);
  }

  get msiComponents() {
    const filtered = this.index.msiIndexVersions[0].msiIndexCompositions.filter(entry => entry.score >= 5);

    filtered.map(entry => {
      entry.stockExchange = this.index.stockExchange?.composition?.find(e => e.stock_exchange_id === entry.stock_exchange_id).stockExchange;
    })

    return filtered;
  }

  get compositionChunks() {
    const c = this.index.stockExchange?.composition;
    if (c) {
      const l = c.length;
      return [
        c.slice(0, l / 2),
        c.slice(l / 2),
      ];
    }

    return [];
  }

  setData(d: Record<string, Record<string, number>[]>) {
    this.samples = d.samples;
    this.last = d.last;
  }

  reloadCompoInterval = 0;
  @Watch("live")
  setLive(live: boolean) {
    if (live) {
      this.reloadCompoInterval = window.setInterval(() => {
        this.loadMsiIndex();
      }, 3000);
    } else {
      window.clearInterval(this.reloadCompoInterval);
    }
  }

  beforeDestroy() {
    window.clearInterval(this.reloadCompoInterval);
  }


  loadMsiIndex() {
    return this.$api.get(`msi-index/${this.indexId}?expand=msiIndexVersions.msiIndexCompositions,stockExchange.composition`).then((res: AxiosResponse<MsiIndex>) => {
      this.index = res.data;
      if (this.index.stockExchange) {
        const now = this.index.stockExchange.last.now;
        this.last = [
          now, //this.toggleBase == 0 ? now : 20000,
          now
        ];
      }
    });
  }

  truncate(text: string, length: number) {
    if (text.length <= length + 3) {
      return text;
    }

    return text.substr(0, length) + ' ..';
  }

  created() {
    this.loadMsiIndex().then(() => {
      this.loading = false;
    });
  }

}
